import parse from "../../../../libs/parse/v0.0.1/main"

const initBackend = async (backendType, props) => {
    switch (backendType) {
        case 'parse':            
            window.Rolder.libs.Parse = parse.init(props)
            window.Rolder.libs.parse = parse

            if (window.Rolder.params.debug > 0) console.log('Parse initialized: ' + props.project + '-' + props.env)
            break
        case 'kuzzle':
            /* const Kuzzle = kuzzle.init({ project: this.inputs.project, env: this.inputs.env })
            try {
                await Kuzzle.connect()                        
                window.Kuzzle = Kuzzle
                window.kuzzle = kuzzle
                console.log('Kuzzle connected')
            } catch (error) {
                console.error(error.message)
            } */
            break
    }
}

export default initBackend