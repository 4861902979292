import fetch from './funcs/fetch'
import create from './funcs/create'
import init from './funcs/init'

const parse = {
    init: (prop) => init(prop),
    fetch: (prop) => fetch(prop),
    create: (prop) => create(prop)
}

export default parse