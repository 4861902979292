const queryFn = {    
    parse: (props) => window.Rolder.libs.parse.fetch(props)
}
//kuzzle.fetch({ index: 'cockbot', collection: className }); break

const query = {
    list: (props) => {
        const backendType = window.Rolder.params.backendType
        return {
            queryKey: [backendType, props],
            queryFn: () => queryFn[backendType](props)
        }
    },
   /*  lists: () => [...messageKeys.all, 'list'],
    list: (className, filters) => {
        return {
            queryKey: [{ className }, { filters }],
            queryFn: () => kuzzle.fetch({ index: 'cockbot', collection: className, filters: filters })
        }
    },
    details: () => [...todoKeys.all, 'detail'],
    detail: (id) => [...todoKeys.details(), id], */
}

export default query