import convert from './convert'

const fetch = async (props) => {
    const debug = window.Rolder.params.debug
    const Parse = window.Rolder?.libs.Parse
    const { className, filters, pagination, options } = props
    
    const parseQuery = Parse.Query.fromJSON(className, filters)
    parseQuery.skip(pagination.skip)
    parseQuery.limit(pagination.limit)
    parseQuery.withCount(options.withCount)
    
    if (debug > 0) console.time(className + ' fetch time')
    if (debug > 1) {
        console.log(className + ' props:', props)
        console.log(className + ' parseQuery: ',parseQuery)   
    }    

    return parseQuery.find()
        .then((response) => {
            const jsonItems = options.withCount ? response.results.map(r => r.toJSON()) : response.map(r => r.toJSON())
            const data = {
                items: jsonItems,
                fetchedCount: jsonItems.length,
                totalCount: response.count
            }

            if (debug > 1) console.log(className + ' fetched:', data)
            if (debug > 0) console.timeEnd(className + ' fetch time')

            return data
        })
}
export default fetch