const Noodl = require('@noodl/noodl-sdk')
import initBackend from './funcs/init-backend'
import query from '../../../libs/query-factory/v0.0.1/main'
import { QueryClient, useQuery, useMutation, QueryClientProvider, } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools/build/lib/index.prod.js'
import "primereact/resources/primereact.min.css"
import "primereact/resources/themes/md-dark-indigo/theme.css"
import { ListBox } from 'primereact/listbox'; import { DataView } from 'primereact/dataview'; import { Card } from 'primereact/card';

const queryClient = new QueryClient()

const InitializerNode = Noodl.defineNode({
    name: 'Initializer v0.0.1',
    inputs: {
        backend: {
            type: {
                name: 'enum',
                enums: [{
                    value: 'parse',
                    label: 'Parse'
                }, {
                    value: 'kuzzle',
                    label: 'Kuzzle'
                }]
            },
            displayName: 'Backend',
            group: 'Connection params',
            default: 'parse',
        },
        env: { type: 'string', displayName: 'Environment', group: 'Connection params', description: 'Environment and version, examples: d2, s1' },
        project: { type: 'string', displayName: 'Project name', group: 'Connection params', description: 'Examples: rasko, tex' },
        apiKey: { type: 'string', displayName: 'API key', group: 'Connection params', description: 'Backend API key' },
    },
    outputs: {
        inited: { type: 'signal', displayName: 'Initialized' }
    },
    signals: {
        initialize: async function () {
            // debug
            const urlParams = new URLSearchParams(window.location.search)
            let debug = false
            if (urlParams.get('debug')) debug = urlParams.get('debug')            
            
            // structure
            window.Rolder = {
                //initialized: false,
                params: {
                    backendType: this.inputs.backend,
                    debug: debug
                },
                libs: {
                    rq: {}
                },
                comps: {},
            }                        

            // choose and initialize backend
            initBackend(
                this.inputs.backend,
                {
                    project: this.inputs.project,
                    env: this.inputs.env,
                    apiKey: this.inputs.apiKey
                }).then(() => {
                    // query-factory            
                    window.Rolder.libs.query = query

                    // ReactQuery
                    window.Rolder.libs.rq.client = queryClient
                    window.Rolder.libs.rq.useQuery = useQuery
                    window.Rolder.libs.rq.useMutation = useMutation
                    window.Rolder.libs.rq.QueryClientProvider = QueryClientProvider
                    window.Rolder.libs.rq.ReactQueryDevtools = ReactQueryDevtools

                    //primereact
                    //window.pr = { ListBox, DataView, Card }
                    
                    if (debug > 0) console.log('Rolder', window.Rolder)
                    this.sendSignalOnOutput('inited')
                })
        }
    },
})

Noodl.defineModule({
    nodes: [InitializerNode],
    setup() { }
});