import convert from './convert'

const create = async (props) => {
    const { index, collection, body } = props
    try {
        console.time('create ' + collection)
        await Kuzzle.connect()
        const results = await Kuzzle.document.create(index, collection, body)
        console.timeEnd('create ' + collection)
        console.log(convert(results))
        return convert(results)
    } catch (error) {console.error(error.message)}
}
export default create